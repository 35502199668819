import request from "@/utils/request.js";

export default {
  // 获取留言列表
  getBoard(obj) {
    return request({
      url: "/board/getBoard",
      method: "post",
      data: obj,
    });
  },
  // 跟进留言
  followBoard(obj) {
    return request({
      url: "/board/followBoard",
      method: "post",
      data: obj,
    });
  },
};
