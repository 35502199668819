<template>
	<div class="board">
		<div class="board_content">
			<div class="board_label flex">
				<p class="board_label-left">留言板功能设置</p>
				<el-switch
					class="board_label-center"
					active-color="#009688"
					v-model="boardShow"
					active-text="功能启用"
					@change="save(1)"
				>
				</el-switch>
				<div class="board_label-right">
					<el-button size="medium" type="primary" @click="dialogVisible = true"
						>管理留言板</el-button
					>
				</div>
			</div>
			<el-divider></el-divider>
			<div class="board_search flex">
				<el-date-picker
					v-model="searchTimeValue"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="searchTime"
				>
				</el-date-picker>

				<!-- <el-button type="primary">搜索</el-button> -->
			</div>

			<div class="board_table">
				<el-table
					:data="tableData"
					stripe
					style="width: 100%;border: 1px solid #e8e8e8"
					:header-cell-style="{ background: '#eef1f6', color: '#606266' }"
					:rowStyle="{ height: '40px' }"
				>
					<el-table-column prop="name" label="姓名"> </el-table-column>
					<el-table-column prop="tel" label="联系电话"> </el-table-column>
					<el-table-column prop="desc" label="留言内容">
						<template slot-scope="scope">
							<el-popover
								placement="top-start"
								width="200"
								trigger="hover"
								:content="scope.row.desc"
								v-show="scope.row.boardId"
							>
								<p class="table_font" slot="reference">
									{{ scope.row.desc }}
								</p>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="留言时间">
					</el-table-column>
					<el-table-column
						v-for="(item, index) in boardBar"
						:key="index"
						:prop="item.value"
						:label="item.label"
					></el-table-column>
					<el-table-column prop="remarks" label="跟进内容">
						<template slot-scope="scope">
							<el-popover
								placement="top-start"
								width="200"
								trigger="hover"
								:content="scope.row.remarks"
								v-show="scope.row.id"
							>
								<p class="table_font" slot="reference">
									{{ scope.row.remarks }}
								</p>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button
								type="text"
								v-show="scope.row.id"
								@click="followClick(scope.row)"
								>跟进</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<el-pagination
				class="pagination flex"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:page-sizes="[5, 10, 15, 20]"
				:page-size="rows"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
			<el-dialog title="管理留言板" :visible.sync="dialogVisible" center>
				<BoardParam
					:boardBar="boardBar"
					@cancelDialog="cancelDialog"
					@submitParam="submitParam"
					@deleteParam="deleteParam"
			/></el-dialog>
		</div>

		<div class="board_content">
			<div class="board_label flex">
				<p class="board_label-left">悬浮框按钮设置</p>
				<el-switch
					class="board_label-center"
					active-color="#009688"
					v-model="levitateShow"
					active-text="功能启用"
					@change="save(2)"
				>
				</el-switch>
				<div class="board_label-right">
					<p>
						*
						指始终悬浮在网站右边的按钮，需开启功能并在概况填写联系信息才能正常显示和使用
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BoardParam from "./BoardParam.vue";
import api from "@/api/website.js";
import apiBoard from "@/api/board.js";
import utils from "@/utils/utils.js";

export default {
	data() {
		return {
			// 日期选择
			searchTimeValue: ["", ""],
			// 留言板开关
			boardShow: false,
			// 悬浮框开关
			levitateShow: false,
			tableData: [],
			// 留言项
			boardBar: [],
			rows: 5,
			page: 1,
			total: 0,
			// 对话框
			dialogVisible: false,
		};
	},
	mounted() {
		this.getBoardList();
		// 配置信息
		this.boardShow = this.$store.getters.boardShow;
		this.boardBar = this.$store.getters.boardBar;
		this.levitateShow = this.$store.getters.levitateShow;
	},
	methods: {
		// 分页
		handleSizeChange(val) {
			this.rows = val;
			this.getBoardList();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getBoardList();
		},
		// 获取线索列表
		async getBoardList() {
			try {
				let condition = {
					pageInfo: {
						rows: this.rows,
						page: this.page,
					},
					reqData: {
						startTime: this.searchTimeValue[0]
							? utils.format(this.searchTimeValue[0], "yyyy-MM-dd HH:mm:ss")
							: "",
						endTime: this.searchTimeValue[1]
							? utils.format(this.searchTimeValue[1], "yyyy-MM-dd HH:mm:ss")
							: "",
						companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
					},
				};
				const res = await apiBoard.getBoard(condition);
				if (res.data.code == 200 && res.data.result) {
					if (res.data.result.list.length > 0) {
						let list = res.data.result.list;
						list.forEach((item) => {
							item.createTime = utils.format(
								item.createTime,
								"yyyy-MM-dd HH:mm:ss"
							);
							if (item.more) {
								let more = JSON.parse(item.more);
								more.forEach((item2) => {
									item[item2.paramId] = item2.value;
								});
							}
						});
						// 数量不足，补齐
						for (let i = 1, num = this.rows - list.length; i <= num; i++) {
							list.push({});
						}
						this.tableData = list;
						this.total = res.data.result.total;
					} else {
						this.tableData = [];
						this.total = 0;
					}
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 选中日期
		searchTime() {
			this.page = 1;
			this.getBoardList();
		},
		// 关闭弹窗
		cancelDialog() {
			this.dialogVisible = false;
		},
		// 保存留言项
		submitParam(val) {
			this.boardBar = this.boardBar.concat(val);
			this.save(3);
			this.dialogVisible = false;
		},
		// 删除留言项
		deleteParam(index) {
			this.boardBar.splice(index, 1);
		},
		// 保存功能
		async save(val) {
			try {
				let condition = {};
				if (val == 1) {
					condition.boardShow = this.boardShow;
				}
				if (val == 2) {
					condition.levitateShow = this.levitateShow;
				}
				if (val == 3) {
					condition.boardBar = this.boardBar;
				}
				const res = await api.saveWebInfo(condition);
				if (res.data.code == 200) {
					this.$message({
						type: "success",
						message: "保存成功！",
						duration: 1000,
					});
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 跟进弹窗
		followClick(val) {
			const that = this;
			this.$prompt("请输入跟进内容", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				inputValue: val.remarks,
			})
				.then(({ value }) => {
					// 提交跟进信息
					that.submitFollow({ remarks: value, boardId: val.boardId });
				})
				.catch(() => {});
		},
		async submitFollow(val) {
			try {
				let condition = {
					remarks: val.remarks,
					companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
					boardId: val.boardId,
				};
				const res = await apiBoard.followBoard(condition);
				if (res.data.code == 200) {
					this.$message({
						type: "success",
						message: res.data.message,
						duration: 1000,
					});
					this.tableData.forEach((item) => {
						if (item.boardId == val.boardId) {
							item.remarks = val.remarks;
						}
					});
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
	components: {
		BoardParam,
	},
};
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.board {
	padding: 10px;
	&_content {
		background-color: $bgColor;
		border-radius: 10px;
		padding: 20px 0;
		color: $fontColor;
		margin-bottom: 20px;
	}

	&_label {
		align-items: center;

		&-left {
			border-left: 6px solid #31b1ff;
			padding-left: 5px;
			font-weight: bold;
			font-size: 20px;
		}

		&-center {
			margin: 0 40px;
		}

		&-right {
			color: $fontShallow;
		}
	}

	&_search {
		align-items: center;
		padding: 0 20px;

		> div {
			margin-right: 20px;
		}
	}

	&_table {
		margin: 20px;
	}

	.pagination {
		justify-content: flex-end;
		margin: 20px;
	}

	.el-dialog__body {
		display: flex;
		justify-content: center;
	}

	.el-date-editor .el-range-separator {
		padding: 0;
	}
}
</style>
