<template>
  <div class="boardParam">
    <div class="boardParam_th flex">
      <div class="th_name">留言项</div>
      <div class="th_required">必填</div>
      <div class="th_func">操作</div>
    </div>
    <div class="boardParam_td flex">
      <div class="td_name">姓名</div>
      <div class="td_required">
        <el-switch active-color="#009688" v-model="required" disabled />
      </div>
      <div class="td_func"></div>
    </div>
    <div class="boardParam_td flex">
      <div class="td_name">联系电话</div>
      <div class="td_required">
        <el-switch active-color="#009688" v-model="required" disabled />
      </div>
      <div class="td_func"></div>
    </div>
    <div class="boardParam_td flex">
      <div class="td_name">留言</div>
      <div class="td_required">
        <el-switch active-color="#009688" :value="false" disabled />
      </div>
      <div class="td_func"></div>
    </div>
    <div
      class="boardParam_td flex"
      v-for="(item, index) in boardBar"
      :key="item.label"
    >
      <div class="td_name">
        <el-input size="small" v-model="item.label" style="width: 4rem" />
      </div>
      <div class="td_required">
        <el-switch active-color="#009688" v-model="item.required" />
      </div>
      <div class="td_func">
        <el-button type="text" style="color: red" @click="deleteParam(index)"
          >删除</el-button
        >
      </div>
    </div>
    <div
      class="boardParam_td flex"
      v-for="(item, index) in temporaryParams"
      :key="index"
    >
      <div class="td_name">
        <el-input size="small" v-model="item.label" style="width: 4rem" />
      </div>
      <div class="td_required">
        <el-switch active-color="#009688" v-model="item.required" />
      </div>
      <div class="td_func">
        <el-button
          type="text"
          style="color: red"
          @click="delTemporaryParams(index)"
          >删除</el-button
        >
      </div>
    </div>
    <el-button type="success" @click="addParam" style="margin: 20px 0"
      >+ 添加留言项</el-button
    >
    <div class="dialog-footer">
      <el-button type="primary" @click="submit">保 存</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    boardBar: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      required: true,
      temporaryParams: [],
    };
  },
  methods: {
    // 添加留言项
    addParam() {
      // 生成随机id
      let id = JSON.parse(localStorage.getItem("userInfo")).companyId;
      var str =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (var i = 2; i > 0; --i) {
        id += str[Math.floor(Math.random() * str.length)];
      }
      let flag = true;
      this.boardBar.forEach((item) => {
        if (item.value == id) {
          flag = false;
        }
      });
      if (flag) {
        this.temporaryParams.push({
          value: id,
          label: "",
          required: false,
        });
      } else {
        this.addParam();
      }
    },
    // 删除留言项
    deleteParam(index) {
      this.$confirm("此操作将永久删除该留言项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("deleteParam", index);
          this.$message({
            type: "success",
            message: "删除成功!",
            duration: 800,
          });
        })
        .catch(() => {});
    },
    // 删除留言项
    delTemporaryParams(index) {
      this.temporaryParams.splice(index, 1);
    },
    // 关闭弹窗
    cancel() {
      this.$emit("cancelDialog");
      this.temporaryParams = [];
    },
    // 保存留言项
    submit() {
      this.$emit("submitParam", this.temporaryParams);
      this.temporaryParams = [];
    },
  },
};
</script>

<style lang="scss">
.boardParam {
  text-align: left;
  &_th {
    height: 40px;
    width: 800px;
    background-color: #f5f5f5;
    align-items: center;
    padding-left: 40px;
    font-weight: bold;

    .th_name {
      width: 60%;
      text-align: left;
    }

    .th_required,
    .th_func {
      width: 20%;
    }
  }

  &_td {
    height: 50px;
    width: 800px;
    line-height: 50px;
    padding-left: 40px;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;

    .td_name {
      width: 60%;
      text-align: left;
    }

    .td_required,
    .td_func {
      width: 20%;
    }
  }

  &_error {
    height: 40px;
    width: 800px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #e8e8e8;
    color: #999999;
  }

  &_hint {
    margin: 20px 0;
    color: #999999;
    text-align: left;
  }
}
</style>
